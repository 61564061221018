.footer-section {
  background-color: #1d293f;
  color: #fff;
}
.footer {
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 6rem;
  padding-bottom: 0;
}
.footer-a {
  text-decoration: none;
  color: #e9f7fe;
}

.footer-a:hover,
.footer-contact-link:hover {
  transform: translateY(-2px);
  color: #fff;
}
li {
  list-style: none;
}

.footer-link-header a {
  color: #8c97ac;
}
.footer-link-header {
  padding-bottom: 2rem;
  padding-left: 40px;
}
.footer-p {
  color: #8c97ac;
  padding: 2rem 6rem 0 0;
  font-size: 16px;
}
.links p {
  padding-left: 40px;
  font-size: 12px;
  color: #e9f7fe;
}

.links li {
  padding-bottom: 1rem;
  font-size: 13px;
}
.footer-contact-link {
  color: #ffd166;
}

.footer-input {
  margin-right: 0px;
  outline: none;
  width: 269px;
  border-radius: 11px;
  font-size: 14px;
}
.footer-input::placeholder {
  font-size: 12px;
}

.footer-btn {
  color: #fff;
  background-image: linear-gradient(to right, #3718b6, #431f7d);
  border-radius: 8px;
  width: 130px;
  margin-left: -1rem;
  font-size: 14px;
}
.footer-btn,
.footer-input {
  display: inline-block;
  padding: 8px 10px;
  height: 45px;
  border: none;
}
.footer-form {
  padding-top: 30px;
  width: 383px;
  display: flex;
}

.footer-line {
  margin-top: 6rem;
  height: 0.5px;
  background-color: #8c97ac;
}
.copyright-text {
  padding: 5px 0;
  color: #8c97ac;
  font-size: 11px;
}
.footer-mobile-form {
  display: none;
}
.footer-mobile {
  display: none;
}

@media screen and (max-width: 1100px) {
  .footer {
    padding-left: 6%;
    padding-right: 0;
  }
  .footer-starter {
    max-width: 35%;
    flex: 0 0 35%;
  }
  .footer-input {
    width: 200px;
  }
  .footer-btn {
    width: 120px;
  }
  .footer-img {
    width: 120px;
  }
  .footer-line {
    margin-right: 6%;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    font-family: "Poppins";
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 4rem;
  }
  .footer-line {
    margin-right: 1.5rem;
  }
  .footer-middle {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .footer-starter {
    max-width: 300px;
  }
  .footer-links {
    text-align: center;
    padding-bottom: 1.4rem;
  }
  .footer-img {
    width: 120px;
  }
  .footer-mobile-form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-form {
    display: none;
  }
  .footer-mobile {
    text-align: center;
    display: inline-block;
    color: #8c97ac;
  }
  .links li {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .footer-starter {
    text-align: center;
  }
  .footer-link-header,
  .links p,
  .links ul {
    padding-left: 0px;
    font-size: 16px;
  }
  .contact {
    padding-bottom: 0.5rem;
  }
  .links p {
    font-size: 14px;
    padding: 0.5rem;
  }
  .footer-p {
    padding: 2rem;
    padding-top: 1rem;
  }
  .copyright-text p {
    text-align: center;
  }
  .footer-mobile p {
    padding: 0 4rem;
  }
  .footer-link-header {
    padding-bottom: 10px;
  }
  .footer-btn {
    border-radius: 8px;
  }
  .footer-line {
    margin-top: 3rem;
  }
  .footer-mobile-form {
    margin-top: 1rem;
  }
}
@media (max-width: 400px) {
  .footer-input {
    width: 180px;
  }
  .footer-btn {
    width: 100px;
    border-radius: 8px;
  }
}
