.main-container {
  padding: 0 9%;
}
.contact-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
}
.main-view {
  background: linear-gradient(
    306.97deg,
    #45108a 12.12%,
    #3d065f 41.04%,
    #200554 116.01%
  );
  margin: 198px auto;
  color: white;
  border-radius: 40px;
  width: 93%;
  padding: 4rem;
  max-width: 1000px;
}
.form-aspect {
  font-size: 14px;
  padding-left: 2rem;
}
input, textarea {
  outline: none;
}
.contact-input,.contact-input:focus {
  padding: 8px 20px;
  height: 48px;
  width: 90%;
  border: 1px solid #ece6e6;
}
.text-area{
  border: 1px solid #ece6e6;
  padding: 8px 20px;
}
.contact-form {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
  width: 100%;
  padding-bottom: 4rem;
}

.form-heading {
  color: #666666;
}
.form-fields {
  margin-left: 2.2rem;
  padding-top: 0.9rem;
  flex-direction: column;
}
.text-area {
  width: 90%;
  height: 9rem;
}

.contact-button {
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
  padding: 0.9rem 1.3rem;
}
.contact-btn {
  color: #fff;
  background: linear-gradient(270deg, #3718b6 -12.14%, #431f7d 109.94%);
  padding: 8px;
  border: none;
  height: 45px;
  width: 220px;
  border-radius: 6px;
}

.form-heading p {
  margin-bottom: 0.4rem;
}
.form-name {
  padding-top: 2.3rem;
}

.get-in-touch h1 {
  padding-bottom: 2rem;
  font-family: "Brfirma-Bold";
  font-size: 2.2rem;
}
.get-in-touch p {
  font-size: 13px;
}
.social-links {
  padding: 4rem 0 0 0;
}

.social-links li {
  display: inline-block;
  padding-left: 0.6rem;
}

.company-info {
  display: flex;
  padding-top: 2rem;
}
.company-info a, .company-info p {
  padding-left: 29px;
  font-size: 14px;
  color: #fff;
}

.message-start {
  padding-top: 4rem;
}
.contact-img {
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  z-index: -1;
  right: 8rem;
  top: -6.4rem;
}
.mobile-container {
  display: none;
}
.social-part {
  display: none;
}
.icon-svg {
  width: 90%;
  height: 90%;
}
.success-mssg {
  top: -73%;
}
@media screen and (max-width: 1100px) {
  .main-container {
    padding: 0 3%;
  }
  .contact-img {
    right: 3.5rem;
  }
}
@media screen and (max-width: 991px) {
  .social-links li {
    padding-left: 0.4rem;
  }
}


@media screen and (max-width: 900px) {
  .contact-container {
    display: none;
  }
  .mobile-container {
    display: flex;
    justify-items: center;
    justify-content: center;
  }
  .contact-mobile-view {
    background: linear-gradient(
      306.97deg,
      #45108a 12.12%,
      #3d065f 41.04%,
      #200554 116.01%
    );
    color: #fff;
    padding-top: 2rem;
    padding-bottom: 5rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
    max-width: 100%;
    width: 500px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .get-in-touch-mobile {
    text-align: center;
    justify-content: center;
    max-width: 100%;
    padding: 0.5rem 2rem 0 2rem;
    margin-left: 0rem;
  }
  .get-in-touch-mobile h1 {
    font-size: 1.7rem;
    letter-spacing: 0.5px;
    font-family: "Brfirma-Bold";
  }
  .get-in-touch-mobile p {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 1.8;
    padding: 0.5rem 3rem 0 3rem;
  }
  .contact-form-mobile {
    background-color: #fff;
    border-radius: 8px;
    max-width: 400px;
    width: 85%;
    padding: 0.5rem 2.5rem 2.5rem;
    margin: auto;
    margin-top: 2rem;
  }
  .form-fields-mobile {
    flex-direction: column;
  }
  .form-heading-mobile {
    color: #666666;
    padding-top: 0.6rem;
  }
  .company-details-mobile {
    /* max-width: 75%;
    width: 60%; */
    justify-content: center;
    margin: auto;
    flex-direction: column;
  }
  .company-info-mobile {
    display: flex;
    padding-top: 2rem;
  }
  .message-mobile {
    padding-top: 4rem;
  }
  .company-info-mobile p {
    padding-left: 29px;
    letter-spacing: 0.5px;
  }
  .social-part {
    display: block;
  }
  .contact-button {
    justify-content: center;
    margin-top: 1.5rem;
  }
  .form-name {
    padding-top: 2rem;
  }
  .contact-input {
    width: 100%;
  }
  .text-area {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .contact-mobile-view {
    margin-top: 6rem;
    border-radius: 12px;
    padding-bottom: 4rem;
  }
  .contact-form-mobile {
    width: 88%;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
  }
  .get-in-touch-mobile {
    padding: 0.5rem 1rem 0 1rem;
  }
  .get-in-touch-mobile h1 {
    font-size: 1.4rem;
  }
  .get-in-touch-mobile p {
    padding: 0.5rem 1rem 0 1rem;
    line-height: 1.5;
    font-size: 13px;
  }
  .company-details-mobile {
    margin-top: 2rem;
  }
  .company-info-mobile {
      padding-top: 1.5rem;
  }
}
