.ydyhome-container {
  padding: 0;
}
.ydyhome-hero {
    background: linear-gradient(306.97deg, #45108A 12.12%, #3D065F 41.04%, #200554 116.01%);
    padding-top: 13rem;
    padding-left: 12%;
    position: relative;
}
.ydyhome-hero-sub {
  /* background-image: url("../../assets/man-holding.svg"); */
  color: #ffffff;
  padding-bottom: 6.6rem;
  background-size: contain;
  background-repeat: no-repeat;
}
.ydyhome-hero-sub > div > h1 {
  font-family: "Brfirma-Bold";
  max-width: 700px;
}
.ydyhome-hero-sub > div > p {
    width: 44%;
    margin-top: 2rem;
    min-width: 300px;
}
.ydyhome-hero-sub > div > p > div:nth-child(1) {
  margin-bottom: 10px;
}
.ydyhome-hero-sub > div > button {
  color: #ffffff;
  background: linear-gradient(270deg, #3718b6 -12.14%, #431f7d 109.94%);
  border-radius: 6px;
  margin: 1rem 0;
  border: 1px solid #431f7d;
  width: 220px;
  height: 45px;
  transition: 0.3s;
  outline: none;
}
.ydyhome-man-image {
  right: 10px;
  position: absolute;
  bottom: -2rem;
  width: 60vw;
}
.ydyhome-partners {
    background: #ffffff;
    padding: 4rem 4rem 0;
    text-align: center;
}
.ydyhome-partners > p {
  color: #8c97ac;
}
.ydyhome-partners img {
  transform: scale(0.8);
}
.ydyhome-partners > hr {
    width: 90%;
    margin-top: 4rem;
    margin-right: 3rem;
}
.ydyhome-atyudimy {
  background: #ffffff;
  display: flex;
  width: 1000px;
  margin: auto;
  padding: 2rem 0 3rem;
}
.ydyhome-atyudimy > img {
  width: 45%;
}
.ydyhome-atyudimy > div {
  padding-left: 2rem;
}
.ydyhome-atyudimy > div > h5 {
  font-family: "Brfirma-Bold";
  color: #431f7d;
  font-size: 20px;
}
.ydyhome-atyudimy > div > p {
  color: #666666;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 1rem;
}
.ydyhome-atyudimy > div > p > div {
  margin-top: 8px;
}
.ydyhome-atyudimy > div > button {
    color: #ffffff;
    background: linear-gradient(
270deg
, #3718B6 -12.14%, #431F7D 109.94%);
    border-radius: 6px;
    height: 41px;
    border: 1px solid #ffffff;
    width: 190px;
    transition: 0.3s;
}
::-webkit-scrollbar {
    height: 2px;
}
.ydyhome-whyyudimy {
    text-align: center;
    padding: 2rem;
}
.ydyhome-whyyudimy > h3 {
    color: #431F7D;
    font-family: 'Brfirma-Bold';
    margin-bottom: 1rem;
}
.ydyhome-whyyudimy > p {
    line-height: 1.8;
    color: #666666;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
}
.ydyhome-reasons {
    display: flex;
    margin: 4rem 9%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 1rem;
}
.ydyhome-why {
    display: flex;
    background: #F7F8FA;
    border-radius: 10px;
    padding: 1rem;
    width: 30%;
    text-align: left;
    margin-bottom: 2rem;
    min-width: 270px;
    margin-left: 2rem;
}
.ydyhome-why-circle {
    width: 40px;
    height: 40px;
    aspect-ratio: 1;
    background: #ffffff;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ydyhome-why-box {
    width: 15px;
    height: 15px;
    background: #431F7D;
    box-shadow: 5px 5px 0 0 #431f7d50;
    margin: -5px 0 0 -5px;
}
.ydyhome-why-txt > h6 {
    font-family: 'Brfirma-Bold';
    color: #333333;
}
.ydyhome-why-txt > p {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 1.7;
    margin-top: 1rem;
}
.ydyhome-ourprod {
    padding: 2rem 1rem;
    text-align: center;
}
.ydyhome-ourprod > h3 {
    color: #431F7D;
    font-family: 'Brfirma-Bold';
    margin-bottom: 1rem;
    text-transform: uppercase;
}
.ydyhome-ourprod > p {
    line-height: 1.8;
    color: #666666;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 1100px) {
    .ydyhome-hero {
        padding-left: 5%;
    }
    .ydyhome-man-image {
        width: 68vw;
        bottom: -1.8rem;
    }
    .ydyhome-partners {
        width: 100%;
        padding: 4rem 2rem 0;
        padding-right: 0;
    }
    .ydyhome-atyudimy {
        width: 90%;
    }
    .ydyhome-atyudimy > img {
        width: 50%;
    }
    .ydyhome-atyudimy > div {
        padding-left: 12px;
    }
    .ydyhome-reasons {
        margin: 4rem 5%;
        margin-bottom: 0;
    }
    .ydyhome-why {
        min-width: 300px;
    }
}
@media (max-width: 900px) {
    .ydyhome-hero-sub {
        padding-bottom: 0;
    }
    .ydyhome-hero-sub > div > p {
        width: 80%;
        min-width: 300px;
    }
    .ydyhome-hero-sub > div > button {
        margin-bottom: 0;
    }
    .ydyhome-man-image {
        width: 75vw;
        position: relative;
        right: 0;
        margin-right: 0;
        margin-left: 18vw;
    }
    .ydyhome-atyudimy {
        flex-direction: column-reverse;
        align-items: center;
    }
    .ydyhome-atyudimy > img {
        width: 65%;
        min-width: 450px;
        margin-top: 2rem;
    }
    .ydyhome-atyudimy > div {
        width: 70%;
        min-width: 500px;
    }
    .ydyhome-whyyudimy {
        padding: 2rem 1rem;
    }
    .ydyhome-reasons {
        padding-bottom: 0rem;
    }
    .ydyhome-ourprod {
        padding-bottom: 0.5rem;
    }
}
@media(max-width: 600px) {
    .ydyhome-hero {
        padding-top: 11rem;
        padding-left: 2rem;
    }
    .ydyhome-hero-sub > div > h1 {
        font-size: 2.2rem;
        padding-right: 1.5rem;
    }
    .ydyhome-hero-sub > div > p {
        width: 100%;
        padding-right: 1.5rem;
        line-height: 1.8;
    }
    .ydyhome-man-image {
        margin-left: 13vw;
        width: 80vw;
        bottom: -1.1rem;
    }
    .ydyhome-atyudimy > div {
        width: 100%;
        min-width: 300px;
    }
    .ydyhome-atyudimy > img {
        width: 90%;
        min-width: 300px;
        margin: auto;
        margin-top: 1.5rem;
    }
    .ydyhome-why {
        margin-left: 0;
    }
    .ydyhome-ourprod {
        padding: 1rem;
        padding-bottom: 1px;
    }
    .ydyhome-ourprod > h3{
        text-transform: capitalize;
    }
    .ydyhome-partners img {
        transform: scale(0.65);
      }
}
@media(max-width: 400px) {
    .ydyhome-hero {
        padding-left: 1.5rem;
        padding-top: 9rem;
    }
    .ydyhome-hero-sub > div > h1 {
        font-size: 2rem;
    }
    .ydyhome-hero-sub > div > p {
        font-size: 13px;
        letter-spacing: 0.3px;
    }
    .ydyhome-man-image {
        width: 90vw;
        margin-left: 0;
    }
    .ydyhome-why {
        margin-left: 0;
        min-width: 100%;
    }
    .ydyhome-ourprod > h3, .ydyhome-whyyudimy > h3 {
        font-size: 1.5rem;
    }
}
.react-multi-carousel-track {
    align-items: center;
}