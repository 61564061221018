.headertext {
  font-size: 34px;
  line-height: 131.64%;
  letter-spacing: 0.03em;
  color: #431f7d;
  font-weight: 900;
  padding-bottom: 2rem;
  font-family: "Brfirma-Bold"
}
.section1_about {
  padding-top: 13rem;
  padding-left: 12%;
}
.introtext {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #666666;
}
.section2_about {
  padding-left: 12%;
  padding-right: 3rem;
}
.headertext1 {
  font-size: 24px;
  letter-spacing: 0.03em;
  color: #431f7d;
  font-weight: 900;
  padding-bottom: 0.2rem;
}
.introtext div {
  font-size: 14px;
}
.spirallines {
  transform: rotateY(45deg);
  width: 93%;
}
.topabouttext {
  padding-top: 1rem;
}
.aboutfirstsectionitem {
  position: relative;
  right: 1rem;
  bottom: 2rem;
  width: 78%;
  min-width: 250px;
}
.img22 {
  position: relative;
  top: 2rem;
  width: 98%;
}
.aboutsectionwrap3 {
  padding-top: 1rem;
}
.aboutsectext21 {
  padding-bottom: 1rem;
}
.about-traction {
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.03em;
  color: #431f7d;
  text-align: center;
  font-weight: 900;
}
.polshift123 {
  padding-top: 4rem;
  margin-bottom: 2rem;
}
.tractionroadmap {
  min-height: 1068px;
  width: 100%;
  background-image: url("../../assets/spiraldesign22.svg");
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat;
}

.about-traction1 {
  margin-bottom: 3rem;
}
.about_card1,
.about_card2 {
  background: #ffffff;
  box-shadow: 8px 12px 16px rgba(140, 151, 172, 0.24);
  border-radius: 16px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #666666;
  padding: 1.2rem 1.2rem;
  max-width: 320px;
  height: fit-content;
}
@media (min-width: 1000px) {
  .section1_about {
    padding-left: 12%;
  }
}
@media (min-width: 500px) {
  .about_card1 {
    max-width: 330px;
    margin-right: 3rem;
    margin-bottom: 1rem;
  }
  .tractionroadmap {
    padding-left: 6rem;
  }
  .about_card2 {
    position: relative;
    left: 3rem;
    bottom: 3rem;
  }
}
@media(max-width:1100px){
  .section1_about{
    padding-top: 10rem;
    padding-left: 7%;
  }
  .aboutfirstsectionitem {
    width: 88%;
  }
}
.adjusttop {
  padding-top: 0.6rem;
}
.aboutcardwrap {
  display: flex;
  justify-content: center;
  margin-top: 13rem;
}
.positionleft {
  justify-content: flex-start;
  padding-left: 12%;
  margin-top: 6rem;
}
.yrrs11 {
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;
  letter-spacing: 0.015em;

  color: #666666;
}

.aboutsectext1 {
  padding-bottom: 1rem;
}
.aboutsectext2 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #666666;
}
.team_image_about {
  width: 172px;
  height: 172px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  object-fit: cover;
}
.teamcardcontainer {
  height: 400px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 12px 16px rgba(102, 102, 102, 0.16);
  border-radius: 8px;
  margin-left: 3rem;
  z-index: 2;
}
.teamcontentinfo {
  padding: 1.5rem;
}
.imagecontainer {
  background: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 56%;
}

.member_name {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #1d293f;
}
.jobdescription {
  color: #8c97ac;
}
.membercharacter {
  color: #4f4f4f;
  font-size: 14px;
}
.membercharacter2 {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 700;
}
.teamcardwrap {
  display: flex;
  justify-content: center;
}
.cardcontainer2 {
  /* margin-left: 8rem; */
}
.itemsep {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bgsvgwrapper {
  display: flex;
  justify-content: center;
  height: 30px;
}
.bgsvgwrapper1 {
  width: 351px;
  position: relative;
  /* top: 10rem; */
  z-index: 0;
  left: 1rem;
}
.seperator11 {
  padding-top: 2rem;
}
.about001 {
  padding-bottom: 5rem;
}
.about011 {
  background: #e9f7fe;
  padding-left: 12%;
  padding-top: 3rem;
  padding-bottom: 4rem;
}
.about_press_title {
  font-size: 40px;
  line-height: 64px;
  letter-spacing: -1px;
  color: #1d293f;
  font-weight: 700;
  padding-bottom: 1rem;
}
.blogcard {
  background: white;
  display: flex;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #1d293f;
  width: 480px;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.4rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.blogimage {
  width: 100px;
  height: 100px;
  margin-right: 1rem;
}
.aboutus212 {
  font-size: 13px;
  line-height: 15px;
  color: #453fbe;
  padding-top: 0.8rem;
}
.blogwrapper {
  display: flex;
  flex-wrap: wrap;
}
.rel12 {
  position: relative;
  top: 3rem;
}
@media (max-width: 790px) {
  .section1_about,
  .section2_about {
    padding-top: 8rem;
    padding-left: 5%;
  }
  .section2_about {
    padding-right: 1rem;
  }
  .bgsvgwrapper {
    display: none;
  }
  .aboutfirstsectionitem {
    bottom: 0rem;
    right: 0rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    width: 78%;
  }
  .textright1 {
    text-align: center;
    padding-left: 0px;
  }
  .img22 {
    top: 0rem;
    padding-bottom: 1rem;
  }
  .aboutsectionthree {
    order: 2;
    padding-top: 1rem;
  }
  .sectiontwoimage {
    width: 98%;
  }
  .teamcardwrap {
    flex-wrap: wrap;
  }
  .aboutsectext2 {
    padding-right: 1rem;
  }
  .teamcardcontainer {
    margin-left: 0rem;
    margin-bottom: 2rem;
  }
  .rel12 {
    top: 0rem;
  }
  .aboutcardwrap {
    flex-wrap: wrap;
  }
  .positionleft {
    padding-left: 2%;
  }
  .about_card1 {
    margin-bottom: 4rem;
    margin-top: 3rem;
  }
  .tractionroadmap {
    background: white;
  }
  .aboutcardwrap {
    margin-top: 0rem;
  }
  .about_card2 {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
  .polshift123 {
    padding-top: 2rem;
    margin-bottom: 1rem;
  }
  .about011 {
    padding-left: 4%;
    padding-right: 2%;
  }
  .tractionroadmap {
    background-image: url("../../assets/verticalline.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 9rem;
    background-size: contain;
  }
  .blogcard {
    margin-right: 0.5rem;
  }
  .blogimage {
    width: 80px;
    height: 80px;
    margin-right: 1rem;
  }
}

@media (min-width: 600px) {
  .about_card1 {
    max-width: 330px;
    margin-right: 3rem;
    margin-bottom: 1rem;
  }
  .tractionroadmap {
    padding-left: 6rem;
  }
  .about_card2 {
    position: relative;
    left: 3rem;
    bottom: 3rem;
  }
}

@media (max-width: 600px) {
  .positionleft {
    padding-left: 0px;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
