.ydynavbar {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0px;
    transition: 0.5s;
}
.ydynavbar-purple {
    background: linear-gradient(306.97deg, #45108A 12.12%, #3D065F 41.04%, #200554 116.01%);
}
.ydynavbar-white {
    background: #ffffff;
}
.ydynavbar-desktop {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 12%;
    color: #bcbcbc;
    align-items: center;
}
.ydynavbar-desktop-white{
    background: white;
}
.ydynavbar-desktop-purple{
    background: linear-gradient(306.97deg, #45108A 12.12%, #3D065F 41.04%, #200554 116.01%);
}
.ydynavbar-logo {
    width: 140px;
    cursor: pointer;
}
.ydynavbar-items {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 500px;
}
.ydynavbar-items-list::after {
    content: "";
    display: block;
    content: "";
    display: block;
    border: 1.5px solid #ffffff;
    background: #ffffff;
    border-radius: 5px 5px 0 0;
    margin-top: 5px;
}
.ydynavbar-items-list-purple::after {
    content: "";
    display: block;
    content: "";
    display: block;
    border: 1.5px solid #45108A;
    background: #45108A;
    border-radius: 5px 5px 0 0;
    margin-top: 5px;
}
.ydy-nav-selected {
    color: #ffffff;
}
.ydy-nav-selected-purple{
    color:#3718B6;
    font-weight: 600;
}
.ydy-nav-item-white {
    letter-spacing: 0.3px;
    cursor: pointer;
    color: #45108A;
}
.ydy-nav-item-purple {
    letter-spacing: 0.3px;
    cursor: pointer;
    color: #ffffff;
}
.ydy-nav-item-link {
    text-decoration: none;
    transition: 0.3s;
}
.ydy-nav-item-link:hover {
    text-decoration: none;
    transform: scale(1.1);
}
.ydynavbar-mobile-container {
    display: none;
}
@media(max-width: 1100px) {
    .ydynavbar-desktop {
        padding: 1rem 6%;
    }
}
@media(max-width: 1000px) {
    .ydynavbar-logo {
        width: 130px;
    }
    .ydynavbar-items {
        width: 460px;
        font-size: 14px;
    }
}
@media(max-width: 800px) {
    .ydynavbar-desktop {
        display: none;
    }
    .ydynavbar-mobile-container {
        display: block;
    }
    .ydynavbar-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2.5rem;
        width: 100%;
    }
    .ydymobilelogo {
        width: 130px;
    }
    .ydynavbar-hamburger {
        width: 30px;
        height: 28px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
    }
    .line1 {
        width: 30px;
        background: #ffffff;
        height: 3px;
    }
    .shortline {
        width: 15px;
        background: #ffffff;
        height: 3px;
        margin-left: 15px
    }
    .line1-purple {
        width: 30px;
        background: #45108A;
        height: 3px;
    }
    .shortline-purple {
        width: 15px;
        background: #45108A;
        height: 3px;
        margin-left: 15px
    }
    .ydynavbar-mobile-list {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
    }
    .ydynavbar-mobile-list-item {
        letter-spacing: 0.5px;
        color: #ffffff;
        margin-top: 2rem;
        font-size: 18px;
        text-decoration: none;
        transition: 0.3s;
        text-align: center;
    }
    .ydynavbar-mobile-list-item-white {
        letter-spacing: 0.5px;
        color: #45108A;
        margin-top: 2rem;
        font-size: 18px;
        text-decoration: none;
        transition: 0.3s;
        text-align: center;
    }
    .ydynavbar-mobile-list-item:hover {
        text-decoration: none !important;
    }
    .ydynavbar-mobile-list-item:hover {
        transform: scale(1.1);
        text-decoration: none;
    }
    .close-navbar {
        cursor: pointer;
    }
}
@media(max-width: 600px) {
    .ydynavbar-mobile {
        padding: 1rem 1.5rem;
    }
    .ydynavbar-mobile-list-item {
        font-size: 16px;
    }
    .ydymobilelogo {
        width: 110px;
    }
    .ydynavbar-hamburger {
        height: 24px;
        width: 30px;
    }
    .line1, .line1-purple {
        width: 26px;
        margin-left: 4px;
    }
    .shortline, .shortline-purple {
        width: 17px;
        margin-left: 13px;
    }
}
