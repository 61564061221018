.ydygames-container {
    padding: 0;
}
.ydygames-hero {
    background: #ffffff;
    padding-bottom: 2rem;
    padding-top: 10rem;
    padding-left: 12%;
    padding-right: 12%;
    text-align: center;
}
.ydygames-hero > h1 {
    color: #431F7D;
    font-family: 'Brfirma-Bold';
    line-height: 2;
    margin-bottom: 1rem;
}
.ydygames-hero > p {
    color: #666666;
    max-width: 550px;
    margin: auto;
}
.ydygames-cards {
    width: 100%;
}
.ydygames-cards-imgsec {
    width: 50%;
    margin: auto;
    text-align: center;
}
.ydygames-cards-img {
    width: 90%;
}
.ydygames-cards-img2 {
    width: 65%;
    margin: auto;
}
.ydygames-cards-infosec {
    width: 50%;
    margin-left: 3rem;
}
.ydygames-card-list {
    display: flex;
}
.ydygames-cards ul {
    list-style-image: url("../../assets/Star.png");
}
.ydygames-cutsacross > h3 {
    color: #1D293F;
    text-align: left;
    padding-left: 12%;
    max-width: 550px;
    font-family: 'Brfirma-Bold';
}
.ydygames-cutsacross .ydyhome-reasons {
    border: none;
    margin: 2rem 9%;
    margin-bottom: 0;
    justify-content: flex-start;
}
.ydygames-cards-sec {
    display: flex;
    padding: 0 12%;
    margin-bottom: 9rem;
    justify-content: space-between;
}
.ydygames-cards-sec-1 {
    display: flex;
    flex-direction: column;
    width: 37%;
    justify-content: space-between;
}
.ydygames-cards-sec-1-img {
    height: 47%;
}
.ydygames-cards-sec-2 {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-between;
}
.ydygames-cards-sec-2-a {
    display: flex;
    flex-direction: row;
    height: 70%;
    justify-content: space-between;
}
.ydygames-cards-sec-2-a-img1 {
    width: 40%;
}
.ydygames-cards-sec-2-b {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
}
.ydygames-cards-sec-2-b-img1 {
    height: 50%;
}
.ydygames-cards-sec-2-b-img2 {
    height: 40%;
}
.ydygames-cards-sec-2-c {
    display: flex;
    flex-direction: row;
    height: 25%;
    justify-content: space-between;
}
.ydygames-cards-sec-2-c-img1 {
    width: 71%;
}
.ydygames-cards-sec-2-c-img2 {
    width: 25%;
}
.ydygames-cards-sec-mobile {
    display: none;
}
.ydygames-mobile-card {
    display: none;
}
@media(max-width: 1100px) {
    .ydygames-hero {
        padding-left: 6%;
        padding-right: 6%;
    }
    .ydygames-cutsacross > h3 {
        padding-left: 6%;
        max-width: 450px;
    }
    .ydygames-cards-sec {
        padding: 0 9%;
        margin-bottom: 6rem;
    }
}
@media(max-width: 900px) {
    .ydygames-cards-sec {
        display: none;
    }
    .ydygames-cards-sec-mobile {
        width: 100%;
        overflow-x: auto;
        display: flex;
        margin-bottom: 4rem;
        padding-bottom: 2rem;
    }
    .ydygames-cards-sec-mobile > img {
        margin-left: 2rem;
        width: 200px;
    }
    .ydygames-cards-sec-mobile > img:nth-child(1), .ydygames-cards-sec-mobile > img:nth-child(2) {
        width: 445px;
    }
}
@media(max-width: 800px) {
    .ydygames-hero {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .ydygames-hero > h1 {
        line-height: normal;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
    .ydygames-cutsacross .ydyhome-reasons {
        justify-content: center;
    }
    .ydygames-cutsacross > h3 {
        text-align: center;
        margin: auto;
    }
    .ydygames-mobile-card {
        display: block;
        text-align: center;
    }
    .ydygames-mobile-card > img{
        width: 80%;
        margin: auto;
        max-width: 550px;
    }
}
@media(max-width: 600px) {
    .ydygames-cutsacross > h3 {
        font-size: 1.5rem;
        max-width: 400px;
    }
    .ydygames-cards-sec-mobile {
        margin-top: -2rem;
    }
    .ydygames-cards-sec-mobile > img {
        transform: scale(0.9);
        margin-left: 1rem;
    }
    .ydygames-cards-sec-mobile > img:nth-child(1), .ydygames-cards-sec-mobile > img:nth-child(2) {
        margin-left: 0;
    }
    .ydygames-mobile-card > img{
        width: 85%;
    }
}
@media(max-width: 400px) {
    .ydygames-hero {
        padding-top: 8rem;
    }
    .ydygames-hero > h1 {
        font-size: 1.8rem;
    }
    .ydygames-cards-sec-mobile {
        padding-bottom: 0;
        margin-bottom: 3rem;
        margin-top: -4rem;
    }
    .ydygames-cards-sec-mobile > img {
        transform: scale(0.6);
        margin-left: 0;
        margin-right: -3rem;
    }
    .ydygames-cards-sec-mobile > img:nth-child(1), .ydygames-cards-sec-mobile > img:nth-child(2) {
        margin-left: -4.5rem;
        margin-right: -5rem;
    }
    .ydygames-mobile-card > img{
        width: 90%;
    }
}
.buy_now{
    font-size: 18px;
    line-height: 28px;
    color: #3718B6;
    cursor: pointer;
    font-weight: 700;
}
a,a:hover{
    text-decoration: none;
}