.ydysocials {
    background-image: url("../../assets/socialbg.svg");
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(25, 25, 25, 0.65);
    backdrop-filter: blur(12px);
    background-blend-mode: overlay;
    padding: 4rem 1rem;
}
.ydysocials p {
    color: #C6C6C690;
    font-style: italic;
    font-size: 14px;
    letter-spacing: 0.5px;
}
.ydysocials > div {
    display: flex;
    max-width: 400px;
    margin: auto;
    margin-top: 2rem;
    justify-content: space-between;
    align-items: center;
}
.ydysocials-icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    aspect-ratio: 1;
    border: 1px solid #666666;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ydysocials-icon > img {

}

@media(max-width: 600px) {
    .ydysocials {
        padding: 2.5rem 1rem;
    }
    .ydysocials-icon {
        width: 50px;
        height: 50px;
    }
    .ydysocials p {
        max-width: 300px;
        margin: auto;
    }
}