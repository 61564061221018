@font-face {
  font-family: "Brfirma-Regular";
  src: local("Brfirma-Regular"),
    url("./Fonts/Brfirma/BRFirma-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-Bold";
  src: local("Brfirma-Bold"),
    url("./Fonts/Brfirma/BRFirma-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-BoldItalic";
  src: local("Brfirma-BoldItalic"),
    url("./Fonts/Brfirma/BRFirma-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-ExtraLight";
  src: local("Brfirma-ExtraLight"),
    url("./Fonts/Brfirma/BRFirma-ExtraLight.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Brfirma-Regular";
  color: #150538;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  /* scroll-behavior: smooth; */
  height: 100%;
}
@media(max-width:700px){
  body{
    font-size: 14px;
  }
}

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #32335A; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#32335A; 
}
.newitem{
  overflow-y: scroll;
  min-height: 500px;
  max-height: 525px;
}