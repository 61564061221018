/*Clarity Styling*/
.ydyproducts-clarity {
    display: flex;
    padding: 2rem 0 2rem 12%
}
.ydyproducts-clarity-link {
    color: #453FBE;
    font-weight: 600;
}
.ydyproducts-clarity-link:hover {
    color: #453FBE;
    font-weight: 600;
    text-decoration: underline;
}
.ydyproducts-clarity-ttl {
    margin: 1rem 0 1.5rem;
    color: #333333;
    font-family: 'Brfirma-Bold';
    max-width: 250px;
}
.ydyproducts-clarity-txt {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 1.8;
}
.ydyproducts-clarity-list {
    padding-top: 1rem;
}
.ydyproducts-clarity-list > div {
    display: flex;
    align-items: center;
}
.ydyproducts-icon-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #F7F8FA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 5px;
}
.ydyproducts-clarity-list h6 {
    color: #333333;
    font-family: 'Brfirma-Bold';
}
.ydyproducts-clarity-list span {
    color: #1D293F;
    font-size: 11px;
    background: #FFD166;
    border-radius: 5px;
    padding: 4px;
    font-family: 'Brfirma-Bold';
    margin-top: -4px;
}
.ydyproducts-clarity > div {
    margin: auto 0;
}
.ydyproducts-clarity-img {
    width: 60%;
}

/*Precison Styling*/
.ydyproducts-precision-list span {
    color: #1D293F;
    font-size: 11px;
    background: #FFD166;
    border-radius: 5px;
    padding: 4px;
    font-family: 'Brfirma-Bold';
    margin-top: -4px;
    margin-left: 10px;
}
.ydyproducts-precision-img {
    width: 50%;
}

/*Card Game Styling*/
.ydyproducts-card {
    display: flex;
    padding: 3rem 12% 2rem;
}
.ydyproducts-card-imgsec {
    position: relative;
    margin: auto;
}
.ydyproducts-card-bg {
    background: linear-gradient(
270deg
, #3718B6 -12.14%, #431F7D 109.94%);
    width: 400px;
    height: 400px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-top: 2rem;
}
.ydyproducts-card-imgsec-img {
    position: absolute;
    width: 100%;
    top: 0;
}
.ydyproducts-card-imgsec-img2 {
    width: 88%;
    margin: 0 6%;
    margin-top: 1rem;
    position: absolute;
    top: 0;
}
.ydyproducts-card-infosec {
    margin-left: 4rem;
    width: 100%;
}
.ydyproducts-card-ctrl {
    display: flex;
    justify-content: flex-end;
}
.ydyproducts-card-ctrl > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #431F7D;
    font-size: 16px;
    cursor: pointer;
}
.ydyproducts-card-ctrl > span:nth-child(1) {
    background: rgba(69, 63, 190, 0.1);
    color: #431F7D;
    margin-right: 10px;
}
.ydyproducts-card-ctrl > span:nth-child(2) {
    background: #431F7D;
    box-shadow: 0px 32px 84px rgb(14 86 124 / 17%);
    color: #ffffff;
}
.ydyproducts-card-ttl {
    margin: 1rem 0 1.5rem;
    color: #333333;
    font-family: 'Brfirma-Bold';
}
.ydyproducts-card-txt {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 1.8;
}
.ydyproducts-card-list {
    display: flex;
}
.ydyproducts-card ul {
    list-style-image: url("../../assets/Star.png");
}
.ydyproducts-card li {
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 1.8;
    margin-bottom: 6px;
}
.ydyproducts-card-buy {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 160px;
    border: 2px solid #E2E4E8;
    border-radius: 5px;
    font-size: 12px;
    padding-top: 5px;
    color: #7C8087;
    padding-bottom: 5px;
    margin-top: 1rem;
}
.ydyproducts-card-buy > img  {
    width: 100px;
}

/* Product Hero section */
.ydyproducts-container {
    padding: 0;
  }
.ydyproducts-hero {
      background: #ffffff;
      padding-bottom: 5rem;
      padding-top: 12rem;
      padding-left: 12%;
}
.ydyproducts-hero > h1 {
    color: #431F7D;
    font-family: 'Brfirma-Bold';
    line-height: 2;
    margin-bottom: 1rem;
}
.ydyproducts-hero > p {
    color: #666666;
    max-width: 650px;
}
.ydyproducts-clarity-mobile {
    display: none;
}
/*Responsiveness*/
@media(max-width: 1100px) {
    .ydyproducts-clarity {
        padding-left: 6%;
    }
    .ydyproducts-card {
        display: flex;
        padding: 3rem 6% 2rem;
    }
    .ydyproducts-card-bg {
        width: 300px;
        height: 300px;
        margin-top: 1rem;
    }
    .ydyproducts-hero {
        padding-top: 11rem;
        padding-left: 6%;
  }
}

@media (max-width: 800px) {
    .ydyproducts-hero {
        padding-top: 9rem;
        padding-left: 6%;
        padding-bottom: 3rem;
        padding-right: 1.5rem;
  }
    .ydyproducts-hero > h1 {
        font-size: 2.2rem;
    }
    .ydyproducts-hero > p {
        max-width: 90%;
    }
    /* Clarity */
    .disable-link-underline:hover {
        text-decoration: none;
    }
    .margin-bottom {
        margin-bottom: 4rem;
    }
    .ydyproducts-clarity, .ydyproducts-card {
        display: none;
    }
    .ydyproducts-clarity-mobile {
        display: block;
        border: 0.5px solid #431F7D;
        background: #ffffff;
        border-radius: 8px;
        padding: 1.5rem 1rem;
        margin: 2rem auto;
        max-width: 380px;
    }
    .ydyproducts-clarity-mobile-txt1 {
        margin: 1rem 0;
        line-height: 1.8;
        color: #666666;
    }
    .ydyproducts-clarity-mobile-txt2 {
        color: #431F7D;
        font-weight: 600;
    }
    .ydyproducts-clarity-mobile-arrow {
        margin-left: 2rem;
    }
    .ydycard-ttl {
        color: #431F7D;
        font-family: 'Brfirma-Bold';
    }
}
@media (max-width: 600px) {
    .ydyproducts-hero > h1 {
        font-size: 2rem;
    }
    .ydyproducts-hero {
        padding-top: 8rem;
        padding-left: 1.5rem;
        padding-bottom: 2rem
    }
    .ydyproducts-hero > p {
        max-width: 100%;
    }
    .ydyproducts-clarity-mobile {
        max-width: 350px;
    }
    .margin-bottom {
        margin-bottom: 3rem;
    }
}
@media(max-width: 400px) {
    .ydyproducts-clarity-mobile {
        width: 85%;
        padding: 1rem 4px 1px 1rem;
    }
}